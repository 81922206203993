<template>
  <div class="journalism">
    <div class="journalism-bgew">
      <img
        src="https://cdn.bubbleplan.cn/static/journalism/Group101412x.png"
        alt=""
        class="journalism-bgew-titlew"
      />
      <div class="journalism-top flex">
        <img
          src="https://cdn.bubbleplan.cn/static/home/Group100872x.png"
          alt=""
          class="journalism-top-left"
          @click="$refs.carouserlRef.prev()"
        />
        <img
          src="https://cdn.bubbleplan.cn/static/home/Group100862x.png"
          alt=""
          class="journalism-top-right"
          @click="$refs.carouserlRef.next()"
        />
        <img
          src="https://cdn.bubbleplan.cn/static/home/Group112301112x.png"
          alt=""
          class="journalism-top-title"
        />
        <div class="journalism-top-carpusel">
          <el-carousel
            indicator-position="none"
            arrow="never"
            :initial-index="initialIndex"
            @change="carpuseLchange"
            ref="carouserlRef"
          >
            <el-carousel-item v-for="item in systemNoticeList1" :key="item">
              <img
                :src="item.img"
                alt=""
                class="journalism-top-carpusel-img"
                @click="newsBuuton(item)"
              />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="journalism-top-right1">
          <div class="journalism-top-right1-title">
            {{ systemNoticeList1[initialIndex].name }}
          </div>
          <img
            src="https://cdn.bubbleplan.cn/static/journalism/Group101732x.png"
            alt=""
            class="journalism-top-right1-img"
          />
          <div class="journalism-top-right1-time">
            {{ systemNoticeList1[initialIndex].createTime }}
          </div>
        </div>
      </div>

      <div class="flex journalism-bb" style="padding: 0 62px 0 29px">
        <div class="journalism-carousel">
          <img
            src="https://cdn.bubbleplan.cn/static/journalism/Group101802x.png"
            alt=""
            class="journalism-carousel-top"
          />
          <el-carousel
            trigger="click"
            arrow="never"
            @change="change"
            indicator-position="outside"
            :autoplay="true"
          >
            <el-carousel-item
              v-for="(item, index) in systemBannerList"
              :key="index"
            >
              <div class="">
                <img
                  :src="item.img"
                  alt=""
                  style="width: 100%"
                  @click="bannerButton(item)"
                />
              </div>
            </el-carousel-item>
          </el-carousel>
          <div
            class="journalism-carousel-title"
            v-if="systemBannerList[systemBannerIndex].name"
          >
            <div class="journalism-carousel-title-text">
              {{ systemBannerList[systemBannerIndex].name }}
            </div>
          </div>
        </div>
        <div class="journalism-journalism">
          <div class="journalism-journalism-type flex" style="">
            <div
              v-for="(item, index) in noticeTypeList"
              :key="index"
              :class="
                journalismActiveIndex == index
                  ? 'journalism-journalism-type-li'
                  : 'journalism-journalism-type-li1'
              "
              @click="handleSelect(item, index)"
            >
              {{ item.noticeTypeName }}
            </div>
          </div>
          <div class="journalism-journalism-li">
            <div
              v-for="(item, index) in systemNoticeList"
              :key="index"
              class="ul flex items-center flex-between"
              @click="newsBuuton(item)"
            >
              <div class="ul-left flex items-center flex-between">
                <div class="ul-left-text">{{ item.name }}</div>
              </div>
              <!-- <div class="ul-right">06-19</div> -->
              <div class="ul-right">{{ years(item.createTime) }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="journalism-video flex flex-center">
      <div
        v-for="(item, index) in videoList"
        :key="index"
        class="journalism-video-li"
      >
        <div v-if="!item.playback">
          <img :src="item.img" alt="" style="width: 301px; height: 204px" />
          <img
            src="../../img/home/playback.png"
            alt=""
            class="journalism-video-playback"
            @click="toggleFullScreen(item, index)"
          />
        </div>
        <video
          width="100%"
          height="100%"
          controls
          :src="item.crossAddr"
          :ref="'videoPlayer0'"
          class="videow"
          v-if="item.playback"
          :id="'video' + index"
        ></video>
        <div class="li-title" v-if="!item.playback">
          {{ item.name }}
        </div>
      </div>
    </div> -->
      <div class="journalism-videoTypeClass flex">
        <img
          :src="item.img"
          alt=""
          class="journalism-videoTypeClass-img"
          v-for="(item, index) in videoTypeList"
          :key="index"
          @click="videoTypeButton(item)"
        />
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  data() {
    return {
      initialIndex: 0,
      journalismActiveIndex: 0,
      noticeTypeList: [],
      systemNoticeList: [],
      videoList: [],
      page: {
        pageNum: 1,
        pageSize: 5,
      },
      systemBannerList: [],
      systemBannerIndex: 0,
      videoTypeList: [
        {
          img: "https://cdn.bubbleplan.cn/static/journalism/Group101492x.png",
        },
        {
          img: "https://cdn.bubbleplan.cn/static/journalism/Group101482x.png",
        },
        {
          img: "https://cdn.bubbleplan.cn/static/journalism/Group101462x.png",
        },
        {
          img: "https://cdn.bubbleplan.cn/static/journalism/Group101472x.png",
        },
      ],
      systemNoticeList1: [],
    };
  },
  mounted() {
    this.noticeType();
    this.systemBanner(0);
    this.systemBanner(1);
    this.systemNotice1();
  },
  methods: {
    years(createTime) {
      console.log(createTime);
      var currentDate = new Date(createTime);
      var month = currentDate.getMonth() + 1; // getMonth() 返回的月份是从 0 开始的
      var day = currentDate.getDate();
      // 格式化月份和日期，保证月份和日期为两位数
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;
      return month + "/" + day;
    },
    //公告查询
    async systemNotice1(noticeType) {
      const params = {
        ...this.page,
      };
      const { data } = await this.$ajax.systemNotice(params);
      this.systemNoticeList1 = data.list;
    },
    videoTypeButton(item) {
      if (!item.url) {
        this.$message({
          message: "暂未开放！",
          type: "warning",
        });
        return;
      }
    },
    carpuseLchange(event) {
      this.initialIndex = event;
    },
    change(event) {
      this.systemBannerIndex = event;
      console.log(event);
    },
    //轮播跳转
    bannerButton(item) {
      if (item.crossAddr) {
        this.$router.push(item.crossAddr);
      }
    },
    //轮播
    async systemBanner(type) {
      const { data, code } = await this.$ajax.systemBanner({ type: type });
      if (type == 0) {
        this.systemBannerList = data.list;
      } else {
        data.list.forEach((item, index) => {
          this.videoList.push({ ...item, playback: false });
        });
      }
    },
    //切换
    handleSelect(item, index) {
      this.journalismActiveIndex = index;
      this.systemNotice(this.noticeTypeList[index].noticeType);
    },
    //查询公告分类
    async noticeType() {
      const { data, code } = await this.$ajax.noticeType();
      this.noticeTypeList = data;
      this.systemNotice(this.noticeTypeList[0].noticeType);
      // console.log(this.noticeTypeList);
    },
    //公告查询
    async systemNotice(noticeType) {
      const params = {
        ...this.page,
        noticeType: noticeType,
      };
      const { data } = await this.$ajax.systemNotice(params);
      this.systemNoticeList = data.list;
    },
    //进入公告
    newsBuuton(item) {
      this.$router.push(`/news/newsDetails?id=${item.id}`);
    },

    toggleFullScreen(item, index) {
      this.videoList[index].playback = true;
      setTimeout(() => {
        var myVideo = document.getElementById("video" + index);
        myVideo.play();
        myVideo.addEventListener("ended", function () {
          this.videoList[index].playback = false;
        });
      }, 500);

      return;
    },
  },
};
</script>
  <style lang="scss" scoped>
.journalism::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.journalism {
  width: 100%;
  height: calc(100vh - 94px);
  // background: #021521;
  background: url("https://cdn.bubbleplan.cn/static/journalism/journalism_cc.png")
    no-repeat;
  background-size: 100% 100%;
  flex-direction: column;
  align-items: center;
  padding-top: 26px;
  // padding: 30px 357px 0 319px;
  overflow: auto;
  &-bgew {
    width: 100%;
    height: 1631px;
    background: url("https://cdn.bubbleplan.cn/static/home/announcement_bgc.png")
      no-repeat;
    background-size: 100% 100%;
    padding: 22px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-titlew {
      width: 349px;
      height: 189px;
    }
  }
  &-top {
    width: 1271px;
    height: 475px;
    background: url("https://cdn.bubbleplan.cn/static/journalism/Group101872x.png")
      no-repeat;
    background-size: 100% 100%;
    margin-top: -55px;
    position: relative;
    padding: 55px 56px;
    &-left:hover {
      cursor: pointer;
    }
    &-right:hover {
      cursor: pointer;
    }
    &-left {
      width: 56px;
      height: 56px;
      position: absolute;
      top: 251px;
      left: -14px;
    }
    &-right {
      width: 56px;
      height: 56px;
      position: absolute;
      top: 251px;
      right: 34px;
    }
    &-title {
      width: 205px;
      height: 89px;
      position: absolute;
      z-index: 99;
      top: 70px;
      left: 70px;
    }
    &-carpusel {
      width: 668px;
      height: 396px;
      // height: 368px;
      padding: 14px;
      background: #fff;
      // margin-top: -50.94px;
      :deep(.el-carousel__container) {
        height: 368px;
      }
      &-img:hover {
        cursor: pointer;
      }
      &-img {
        width: 646px;
        height: 368px;
      }
    }
    &-right1 {
      flex: 1;
      padding-top: 153px;
      &-title {
        width: 311px;
        color: #ffffff;
        text-align: center;
        font-size: 25px;
        font-weight: Bold;
        opacity: 0.9;
        margin-left: 77px;
        height: 80px;
      }
      &-img {
        width: 355px;
        height: 15px;
        margin: auto;
        margin-top: 10px;
      }
      &-time {
        margin-top: 12px;
        color: #ffffffe6;
        text-align: center;
        font-size: 18px;
        opacity: 0.9;
      }
    }
  }

  &-bb {
    width: 1255px;
    height: 575px;
    background: url("https://cdn.bubbleplan.cn/static/journalism/Group101812x.png")
      no-repeat;
    background-size: 100% 100%;
  }
  .journalism-carousel {
    width: 600px;
    height: 308px;
    position: relative;
    margin-top: 42px;
    &-top {
      position: absolute;
      top: -40px;
      z-index: 99;
      width: 191px;
      height: 439px;
      left: -50px;
    }
    :deep(.el-carousel__container) {
      height: 308px;
      width: 600px;
    }

    :deep(.el-carousel__indicators--horizontal) {
      left: auto;
      right: 10px !important;
      bottom: 8px;
      display: flex;
      -webkit-transform: translateX(0%);
      transform: translateX(0%);
      justify-content: flex-end;
    }

    :deep(.el-carousel__indicator--horizontal) {
      padding: 12px 5px !important;
    }

    :deep(.el-carousel__indicators--outside button) {
      // width: 9px;
      // height: 9px;
      // border-radius: 50%;
      width: 20px;
      height: 20px;
      background: url("https://cdn.bubbleplan.cn/static/journalism/Frame96142x.png")
        no-repeat;
      background-size: 100% 100%;
      opacity: 1;
    }
    :deep(.el-carousel__indicator.is-active button) {
      width: 20px;
      height: 20px;
      background: url("https://cdn.bubbleplan.cn/static/journalism/Frame96102x.png")
        no-repeat;
      background-size: 100% 100%;
      opacity: 1;
    }

    &-title {
      width: 392px;
      height: 67px;
      flex-shrink: 0;
      background: url("https://cdn.bubbleplan.cn/static/journalism/journalism_title.png")
        no-repeat;
      background-size: 100% 100%;
      position: absolute;
      // bottom: 0;
      top: -6px;
      left: -8px;

      z-index: 999;

      &-text {
        padding: 16px 0 0 33px;
        width: 392px;
        font-size: 20px;
        color: #000000;
        // letter-spacing: 0.36px;
        opacity: 0.9;
        overflow: hidden;
        /* 确保超出容器的文本被隐藏 */
        white-space: nowrap;
        /* 确保文本在一行内显示 */
        text-overflow: ellipsis;
        /* 使用省略号表示被截断的文本 */
      }
    }
  }

  &-journalism {
    margin-left: 47px;
    width: 583px;
    height: 326px;
    // background: #13212f;
    margin-top: 68px;
    &-type {
      height: 58px;
      padding: 12px 0 5px 0;
      &-li {
        width: 115px;
        height: 48px;
        background: url("https://cdn.bubbleplan.cn/static/journalism/Group101202x.png")
          no-repeat;
        background-size: 100% 100%;
        line-height: 48px;
        font-size: 22px;
        text-align: center;
        color: #cbee00;
        margin-right: 26px;
        font-weight: Black;
      }
      &-li1 {
        width: 115px;
        line-height: 48px;
        height: 48px;
        background: url("https://cdn.bubbleplan.cn/static/journalism/Group101302x.png")
          no-repeat;
        background-size: 100% 100%;
        font-size: 22px;
        color: #e9e0ce;
        text-align: center;
        margin-right: 26px;
      }
      &-li:hover {
        cursor: pointer;
      }
      &-li1:hover {
        cursor: pointer;
      }
    }
    &-li {
      // margin-top: 16px;
      padding: 19px 0px;
      padding-right: 20px;
      .ul:hover {
        cursor: pointer;
      }
      .ul {
        padding: 10px 0;
        border-bottom: 1px solid rgba(197, 194, 186, 1);
        .ul-left {
          &-title {
            width: 43px;
            line-height: 24px;
            flex-shrink: 0;
            border: 1px solid #b8c8d3;
            text-align: center;
            color: rgb(184, 200, 211);
            font-size: 19px;
            opacity: 0.9;
          }

          &-text {
            margin-left: 10px;
            width: 400px;
            color: #242225;
            font-size: 16px;
            opacity: 0.9;
            overflow: hidden;
            /* 确保超出容器的文本被隐藏 */
            white-space: nowrap;
            /* 确保文本在一行内显示 */
            text-overflow: ellipsis;
            /* 使用省略号表示被截断的文本 */
          }
        }

        &-right {
          color: #242225;
          font-size: 19px;
          font-weight: Normal;
        }
      }
    }
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }

  .journalism-video {
    margin-top: 15px;

    .li-title {
      position: absolute;
      bottom: 0;
      width: 301px;
      height: 38px;
      flex-shrink: 0;
      background: rgba(0, 0, 0, 0.5);
      color: rgb(255, 255, 255);
      font-size: 16px;
      padding: 0 14px;
      overflow: hidden;
      /* 确保超出容器的文本被隐藏 */
      white-space: nowrap;
      /* 确保文本在一行内显示 */
      text-overflow: ellipsis;
      /* 使用省略号表示被截断的文本 */
      line-height: 38px;
    }
    .videow {
      position: absolute;
      top: 0;
    }
    .journalism-video-playback {
      width: 48.33px;
      height: 48.33px;
      position: absolute;
      top: 68px;
      left: 126px;
    }

    &-li {
      width: 301px;
      height: 204px;
      margin-right: 13px;
      position: relative;
    }

    &-li:nth-child(4n) {
      margin-right: 0px;
    }
  }

  // .video::-webkit-media-controls-enclosure {
  //   display: none;
  // }
  &-videoTypeClass {
    margin-top: 20px;
    width: 1240px;
    height: 341px;
    background: url("https://cdn.bubbleplan.cn/static/journalism/Group101792x.png")
      no-repeat;
    background-size: 100% 100%;
    padding: 55px 34px;
    &-img {
      width: 296px;
      height: 224px;
      margin-right: 13px;
    }
    &-img:nth-child(2) {
      width: 314px;
      height: 259px;
      margin-left: -30px;
      margin-top: -10px;
    }
    &-img:nth-child(3) {
      width: 293px;
      height: 247px;
      margin-left: -30px;
      margin-top: -10px;
    }
    &-img:nth-child(4) {
      width: 320px;
      height: 240px;
      margin-left: -24px;
      margin-top: 10px;
    }
  }
}
</style>